import React from "react";
import * as styles from "./articleList.module.css";
import { Link } from "gatsby";

const defaultArticles = [
  {
    title: "What Is Print On Demand - A Step By Step Guide",
    url: "/posts/what-is-print-on-demand-a-step-by-step-guide/",
  },
  {
    title: "How To Start Print On Demand Business?",
    url: "/posts/how-to-start-print-on-demand-business/",
  },
  {
    title: "Best Tools For Print On Demand Business",
    url: "/posts/best-tools-for-print-on-demand-business/",
  },
  {
    title: "How Much Can You Earn From Print On Demand?",
    url: "/posts/how-much-can-you-earn-from-print-on-demand/",
  },
  {
    title: "How Do You Increase Sales On Print On Demand",
    url: "/posts/how-do-you-increase-sales-on-print-on-demand/",
  },
];

const defaultTitle = (
  <h2 class="mb-4 text-4xl tracking-tight font-extrabold">
    Start <span>Print On Demand Business</span> Today
  </h2>
);
const defaultSubTitle = `Learn to start your e-commerce print on demand business with these
step by step guides.`;

const ArticleList = ({ articles, title, subTitle, themeClass }) => {
  return (
    <section className={`${styles.section_wrapper} ${styles[themeClass]}`}>
      <div class="py-8 px-4 mx-auto max-w-screen-xl lg:py-16 lg:px-6">
        <div class="mx-auto max-w-screen-md text-center mb-8 lg:mb-12">
          {title || defaultTitle}
        </div>
        <div className={`${styles.list_wrapper}`}>
          <p class="font-light text-gray-900 sm:text-lg font-semibold">
            {subTitle || defaultSubTitle}
          </p>
          <ul role="list" class="mb-8 space-y-4 text-left mt-2">
            {(articles || defaultArticles).map((a) => (
              <li class="hover:text-purple-500">
                <a href={a.url} class="flex items-center space-x-3">
                  <svg
                    class="flex-shrink-0 w-5 h-5 text-green-500 dark:text-green-400"
                    fill="currentColor"
                    viewBox="0 0 20 20"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fill-rule="evenodd"
                      d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                      clip-rule="evenodd"
                    ></path>
                  </svg>
                  <span>{a.title}</span>
                </a>
              </li>
            ))}
          </ul>
        </div>
      </div>
      <div className={styles.slider_belowbtn1}>
        <Link
          to="/posts/"
          title="Blog"
          class="text-white bg-purple-700 hover:bg-purple-800 focus:ring-4 focus:ring-purple-300 font-medium rounded-lg text-md px-5 py-2.5 dark:bg-purple-600 dark:hover:bg-purple-700 focus:outline-none dark:focus:ring-purple-800"
        >
          <div className={`px-6 py-1`}>
            <span>Read Latest Blog Posts</span>
            <div className={styles.back}></div>
          </div>
        </Link>
      </div>
    </section>
  );
};

export default ArticleList;
